.custom_modal {
  max-width: 700px !important;
  min-width: 500px !important;
  transform: translate(0, 0) !important;
}

.appuser_detail_title {
  color: #1d253b;
  font-size: 22px;
  font-style: bold;
  font-weight: 600;
  padding-left: 20px;
}

.btn_edit {
  padding: 2px 15px;
  border-radius: 4px;
  border: 2px solid #2a84e9;
  background: #2a84e9;
  color: #fff;
}

/* pagenations */
.row_per_pages {
  padding-top: 10px;
}

.next_prev_btn {
  width: 35px;
  height: 35px;
  border-radius: 20px;
  background-color: "#fff";
  color: #795f90;
  cursor: pointer;
  margin: top 4px;
  border: 0px;
}

.next_prev_btn:hover {
  color: #2a84e9;
}

.next_prev_btn:selected {
  border: 0px;
}

.cccc {
  display: inline-block;
  margin: auto;
  position: relative;
  width: 100%;
}

#currency {
  padding-left: 50px;
}

.currency {
  position: absolute;
  top: 5px;
  left: 10px;
}

.myreacttable {
  overflow: auto;
}

.myreacttable table {
  min-width: 1200px;
}
